@font-face {
	font-family: FaceYourFears;
	src: url('../public/fonts/FaceYourFears.ttf');
}

html {
	height: 100%;
}

body {
	background: #ffffff;
	color: #333;
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 14px;
	margin: 0;
	height: 100%;
}

a {
	color: #777;
	text-decoration: none;
	-moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

a:hover {
	color: #ffda8a;
	text-decoration: underline;
}

img {
	border: 0px;
}
h1 {
	padding:10px
}
input[type=text], input[type=password], input[type=number] {
	background: #fafafa;
	border: 1px solid #e3e3e3;
	color: #666;
}
input[type=text]:focus, input[type=password]:focus, input[type=number]:focus {
	border: 1px solid #ccc;
}
.online {
	height: 10px;
	width: 10px; 
	background-color: green; 
	border-radius: 50%;
	display: inline-block;
	margin-right:5px;
}
.container-fluid {
	padding-left:0px;
	padding-right:0px;
}
#navbar .navbar-nav {
	margin:auto!important;
}
#navbar > .container {
	margin:auto;
}
#headervideo {
	min-width: 1000px;
}
#header {
	width: 100%;
	height: 400px;
	margin: 0px auto;
	text-align: center;
	overflow: hidden;
	color: #fff;
	overflow: hidden;
}
.dropdown-menu {
	font-size: small;
}
#container {
	padding-bottom:10px!important;
	background-color: white;
}
.nav-link {
	padding-left: 20px!important;
	padding-right: 20px!important;
}
 	@media (min-width: 576px) {
		.container, .container-sm {
			margin: none;
			width:65%;
		}
	}
	@media (min-width: 768px) {
		.bd-placeholder-img-lg {
			font-size: 3.5rem;
		}
		.container, .container-md, .container-sm {
			margin: none;
			width:65%;
		}
	}
	@media only screen and (min-width: 1000px) {
		#header > #headervideo {
			position: relative;
			top: -50px;
		}	
		.container, .container-md, .container-sm {
			margin: none;
			width:65%;
			max-width: 1200px;
		}
		#container {
			height: auto;
			margin: 0px auto;
			padding: 20px 0px;
		}
	}
	@media only screen and (min-width: 1300px) {
		#header > #headervideo {
			position: relative;
			top: -100px;
		}
	}
	@media only screen and (min-width: 2000px) {
		#header > #headervideo {
			position: relative;
			top: -150px;
		}
	}
	@media only screen and (max-width: 1000px) {
		#header {
			display:none;
			visibility: hidden;
		}
		.site-logo2 {
			visibility: hidden;
		}
		.col-4 {
			visibility: hidden;
			display: none;
		}
		.col-8 {
			width: 100%;
		}
		.mblock {
			margin:auto;
		}
	}
	#header > .video-shadow {
		background: url('../public/img/bg_shadow.png') repeat;
		position: absolute;
		width: 100%;
		min-height: 400px;
		z-index: 1;
	}

#header > .header-content-container {
	z-index: 2;
	position: absolute;
	top: 50px;
	width: 100%;
	max-height: 400px;
}
#header > .header-front {
	z-index: 2;
}

span.site-logo > img {
		height: 30px;
}
span.site-logo2 > img {
	margin-top:50px;
	height: 120px;
}
#events {
	color: #f7ddbf;
	padding-top:20px;
	padding-left:60px;
}
#events div>small {
	float:right;
	margin-left:4px;
	font-weight:bold;
}
#headerLogo {
	width: 100%;
	margin: 30px 0px;
}

#content {
	width: 100%;
	overflow: auto;
	min-height: 500px;
	padding-top: 20px;
	padding-bottom: 20px;
}

.footer {
	font-family: 'Segoe UI', 'Lucida Sans Unicode', 'Lucida Grande', 'Tahoma', 'Arial', 'sans-serif';
	width: 100%;
	font-size: 14px;
	color: #666;
	padding: 60px 0px;
	background: #000000;
	overflow: auto;
}
	.footer > .footer-container {
		margin: 0px auto;
	}
	.footer-time {
		font-size: 20px;
		color: #ff0000;
		font-weight: bold;
		position: relative;
		top: -10px;
	}
/* CASTLE SIEGE BANNER */
.castle-siege-banner {
	width: 970px;
	background: #000 url('../public/img/castle_siege_banner_bg.jpg') no-repeat center center;
	margin: 0px auto;
	padding: 30px;
	-moz-box-shadow: 0 0 10px #e3e3e3;
	-webkit-box-shadow: 0 0 10px #e3e3e3;
	box-shadow: 0 0 10px #e3e3e3;
	overflow: auto;
	font-family: FaceYourFears;
	color: #fff;
	-moz-border-radius: 10px;
	border-radius: 10px;
}
.cs-guild-title {
	color: #fff;
	font-size: 48px;
}
.cs-guild-guildowner {
	color: #ff0000;
	font-weight: bold;
	font-size: 16px;
	position: relative;
	top: -15px;
}
.cs-timeleft {
	font-family: FaceYourFears;
	font-size: 62px;
	color: #fff;
}
.cs-timeleft span {
	color: #ff0000;
}
/* <-- CASTLE SIEGE BANNER END */


.admincp-button {
	position:absolute;
	top:10px;
	right:10px;
}

.page-title {
	color: #ff0000;
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 32px;
	margin-bottom: 10px;
}

/* NEWS MODULE */
.panel-news {
	margin-bottom: 30px;
	color: #333;
	border: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
	-moz-box-shadow: 0 0 10px #e3e3e3;
	-webkit-box-shadow: 0 0 10px #e3e3e3;
	box-shadow: 0 0 10px #e3e3e3;
}
.panel-news .panel-heading {
	padding-top: 20px;
	padding-bottom: 20px;
	background: #f1f1f1;
}
.panel-news .panel-title {
	color: #000;
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 18px;
}
.panel-news .panel-footer {
	border-top: 1px solid #e7e7e7;
	background: #fff;
	font-style: italic;
	font-size: 11px;
	height: 40px;
	color: #ff0000;
}

/* RANKINGS MODULE */
.rankings-table {
	width: 100%;
	border-spacing: 0;
	border-collapse: collapse;
	table-layout: fixed;
	-moz-box-shadow: 0 0 5px #e3e3e3;
	-webkit-box-shadow: 0 0 5px #e3e3e3;
	box-shadow: 0 0 5px #e3e3e3;
}
.rankings-table img {
	width: 30px;
	height: auto;
	-moz-box-shadow: 0 0 5px #666;
	-webkit-box-shadow: 0 0 5px #666;
	box-shadow: 0 0 5px #666;
	-moz-border-radius: 0px;
	border-radius: 0px;
}
.rankings-table tr td {
	border-bottom: 1px solid #e3e3e3;
	padding: 10px;
	font-size: 18px;
	vertical-align: middle !important;
}
.rankings-table-place {
	color: #666;
	font-weight: bold;
	font-size: 24px;
	text-align: center;
}
.rankings-table tr:first-child td {
	color: #ff0000;
	border-bottom: 3px solid #ff0000;
}
.rankings-update-time {
	text-align: right;
	font-size: 11px;
	color: #ccc;
	padding: 10px 0px;
}
.rankings_menu {
	width: 100%;
	overflow: auto;
	text-align: center;
	margin-bottom: 10px;
}
.rankings_menu span {
	width: 100%;
	display: inline-block;
	padding: 10px 0px;
	color: #ccc;
	font-size: 24px;
}
.rankings_menu a {
	display: inline-block;
	width: 120px;
	border: 1px solid #e3e3e3;
	text-align: center;
	padding: 2px 0px;
	margin: 2px;
	background: #f1f1f1;
	-moz-border-radius: 2px;
	border-radius: 2px;
	color: #ccc;
}
.rankings_menu a.active {
	color: #333;
	border-color: #333;
}
.rankings_guild_logo tr td {
	border: 0px !important;
	padding: 0px !important;
	margin: 0px !important;
}
.rankings-gens-img {
	width: 50px !important;
	height: auto !important;
	border: 0 !important;
	-moz-box-shadow: 0 0 0px #000 !important;
	-webkit-box-shadow: 0 0 0px #000 !important;
	box-shadow: 0 0 0px #000 !important;
	-moz-border-radius: 0px !important;
	border-radius: 0px !important;
}

/* MY ACCOUNT MODULE */
.myaccount-table {
	width: 100%;
	background: #f1f1f1;
	border: 1px solid #e3e3e3;
}
.myaccount-table tr td:first-child {
	color: #666;
	font-weight: bold;
}
.myaccount-table tr td {
	border-bottom: 1px solid #e3e3e3;
	padding: 15px !important;
}
.myaccount-table tr:last-child td {
	border: 0px;
}

/* GENERAL TABLE UI */
.general-table-ui {
	width: 100%;
	table-layout: fixed;
	background: #f1f1f1;
	border: 1px solid #e3e3e3;
	padding: 10px;
	margin: 10px 0px;
}
.general-table-ui tr td {
	padding: 5px;
	vertical-align: middle !important;
}
.general-table-ui tr:first-child td {
	color: #ff0000;
}
.general-table-ui tr:nth-child(2n+2) td {
	background: #fafafa;
}
.general-table-ui tr td {
	text-align: center;
}
.general-table-ui img {
	width: 50px;
	height: auto;
	-moz-box-shadow: 0 0 5px #666;
	-webkit-box-shadow: 0 0 5px #666;
	box-shadow: 0 0 5px #666;
	-moz-border-radius: 0px;
	border-radius: 0px;
}

/* TERMS OF SERVICE PAGE */
.tos_list li {
	color: #ff0000;
	margin-bottom: 40px;
}
.tos_list li p {
	color: #ccc;
	text-align: justify;
	text-justify: inter-word;
	text-transform: none;
}

/* PAYPAL */
.paypal-gateway-container {
width: 100%;
background: #1a2133;
border: 1px solid #2e374f;
}

.paypal-gateway-logo {
width: 100%;
height: 68px;
background: #fff9ec url('../public/img/paypal-logo-200-68.png') no-repeat top center;
margin-bottom: 10px;
-moz-border-radius: 3px;
-webkit-border-radius: 3px;
border-radius: 3px;
}

.paypal-gateway-content {
margin: 10px;
background: #fef2da;
border: 2px solid #f79433;
padding: 10px;
overflow: auto;
}

.paypal-gateway-conversion-rate {
margin: 0px auto;
text-align: center;
color: #000;
font-size: 18px;
padding: 10px 0px;
}

.paypal-gateway-form {
width: 100%;
margin: 0px auto;
text-align: center;
}

.paypal-gateway-form div {
display: inline-block;
padding: 0px 10px;
color: #000;
font-size: 18px;
}

.paypal-gateway-form input[type=text] {
width: 40px;
}

.paypal-gateway-continue {
margin: 0px auto;
text-align: center;
}

.paypal-gateway-continue input[type=submit] {
background: url('../public/img/paypal-submit.jpg') no-repeat;
width: 200px;
height: 40px;
border: 0px;
}

/* CASTLE SIEGE CSS */
.csinfo_container {
	background: url('../public/img/castle_siege.jpg') no-repeat top right;
	width: 100%;
	height: 200px;
	margin-bottom: 20px;
}
.csinfo_content {
	width: 100%;
	height: 180px;
	margin: 10px 0px 0px 10px;
	display: inline-block;
}
.csinfo_glogo {
  float: left;
  width: 30%;
  height: 180px;
  text-align: center;
}
.csinfo_ginfo {
  float: left;
  width: 70%;
  padding: 0px 10px;
}

.csinfo_ginfo table {
table-layout: fixed;
width: 400px;
}
.csinfo_ginfo table tr td {
  padding: 3px 0px;
  font-size: 20px;
}
.csinfo_ginfo table tr td:first-child {
  color: #9a7e46;
}

.csinfo_glist {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
}
.csinfo_glist li {
  float: left;
  width: 30%;
  padding: 8px 10px;
}
.csinfo_glist li:hover {
  background: #0d0c0b;
}

/* PAGSEGURO */
.pagseguro-gateway-container {
	width: 100%;
	background: #1a3322;
	border: 1px solid #2a533c;
}

.pagseguro-gateway-content {
	margin: 10px;
	background: #ffffff;
	border: 2px solid #bfbfbf;
	padding: 10px;
	overflow: auto;
}

.pagseguro-gateway-conversion-rate {
	margin: 0px auto;
	text-align: center;
	color: #000;
	font-size: 18px;
	padding: 10px 0px;
}

.pagseguro-gateway-form {
	width: 100%;
	margin: 0px auto;
	text-align: center;
}

.pagseguro-gateway-form div {
	display: inline-block;
	padding: 0px 10px;
	color: #000;
	font-size: 24px;
}

.pagseguro-gateway-form input[type=text] {
	width: 60px;
	background: #ffffff;
	border: 0px;
	border-bottom: 1px dotted #000;
	color: #1c713a;
	font-weight: bold;
	font-size: 24px;
}

.pagseguro-gateway-continue {
	margin: 0px auto;
	text-align: center;
	padding: 10px;
}

.module-requirements {
	font-size: 12px;
	margin-top: 20px;
}

/* SIDEBAR */
.panel-sidebar {
	background: #f1f1f1;
	border: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
	-moz-box-shadow: 0 0 10px #e3e3e3;
	-webkit-box-shadow: 0 0 10px #e3e3e3;
	box-shadow: 0 0 10px #e3e3e3;
}

.panel-sidebar > .panel-heading {
	background: #fff;
	color: #ff0000;
	font-family: 'Source Sans Pro', sans-serif;
	border: 0px;
	-moz-border-radius: 0px;
	border-radius: 10px 10px 0px 0px;
	border-bottom: 3px solid #ff0000;
}

.panel-usercp {
	background: url('../public/img/usercp_bg.jpg') no-repeat top center;
}

.panel-usercp ul {
	list-style-type: none;
	padding: 0px;
	margin: 0px;
}

.panel-usercp ul li {
	display: table;
	width: 100%;
	vertical-align: middle;
	line-height: 30px;
}

.panel-usercp ul li a {
	color: #444 !important;
	font-weight: bold;
}
.panel-usercp ul li a:active, .panel-usercp ul li a:hover {
	color: #ff0000 !important;
}

.panel-usercp ul li img {
	position: relative;
	top: -2px;
	padding-right: 10px;
}

.sidebar-banner {
	text-align: center;
	margin: 20px 0px;
	-moz-box-shadow: 0 0 10px #e3e3e3;
	-webkit-box-shadow: 0 0 10px #e3e3e3;
	box-shadow: 0 0 10px #e3e3e3;
	border: 0px;
}

.sidebar-banner img {
	border: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
	width:100%;
}

/* GENERAL PANEL STYLING */
.panel-general {
	margin-bottom: 30px;
	background: #f1f1f1;
	border: 1px solid #e3e3e3;
	-moz-border-radius: 0px;
	border-radius: 0px;
	color: #333;
}

.panel-body .panel-title {
	color: #666;
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 14px;
	border-bottom: 1px solid #e3e3e3;
	margin-bottom: 20px !important;
	font-weight: bold;
}
.panel-body {
	padding:10px;
	margin-bottom: 10px;
}
/* ADD STATS MODULE */
.panel-addstats {
	margin-bottom: 30px;
	background: #f1f1f1;
	border: 1px solid #e3e3e3;
	-moz-border-radius: 0px;
	border-radius: 0px;
	color: #333;
}
.panel-addstats .character-avatar img {
	width: 100px;
	height: auto;
	-moz-box-shadow: 0 0 5px #666;
	-webkit-box-shadow: 0 0 5px #666;
	box-shadow: 0 0 5px #666;
	-moz-border-radius: 0px;
	border-radius: 0px;
}
.panel-addstats .character-name {
	color: #666;
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 18px;
	border-bottom: 1px solid #e3e3e3;
	padding: 20px 0px;
	margin-bottom: 20px !important;
	font-weight: bold;
}

.skilltree {
	margin-top:5px;
	display:block;
}
/*
PROFILES
*/
@import url('https://fonts.googleapis.com/css?family=Cinzel');

/* GUILD PROFILES */
.profiles_guild_table {
	width: 100%;
	table-layout: fixed;
	margin: 10px auto;
	background: #0a0908;
	padding: 10px;
}

.profiles_guild_table tr td {
	text-align: left;
}

.profiles_guild_table .gname {
	font-family: 'Cinzel', serif;
	text-align: center;
	font-size: 32px;
	color: #f18a37;
}

.profiles_guild_table .gmembs {
	font-family: 'Cinzel', serif;
	text-align: center;
	font-size: 18px;
	color: #f1b337;
}

.profiles_guild_table .memblist {
	text-align: center;
}

.profiles_guild_table .memblist div {
	float: left;
	width: 20%;
	display: inline;
}

/* PLAYER PROFILES */
.profiles_player_card {
	width: 636px;
	height: 400px;
}

	.DK.profiles_player_card, .BK.profiles_player_card, .BM.profiles_player_card { background: url('../public/img/profiles/knight.jpg') no-repeat; }
	.DW.profiles_player_card, .SM.profiles_player_card, .GM.profiles_player_card { background: url('../public/img/profiles/wiz.jpg') no-repeat; }
	.ELF.profiles_player_card { background: url('../public/img/profiles/elf.jpg') no-repeat; }
	.SUM.profiles_player_card { background: url('../public/img/profiles/sum.jpg') no-repeat; }
	.DL.profiles_player_card, .LE.profiles_player_card { background: url('../public/img/profiles/dl.jpg') no-repeat; }
	.MG.profiles_player_card, .DM.profiles_player_card { background: url('../public/img/profiles/mg.jpg') no-repeat; }
	.RF.profiles_player_card, .FM.profiles_player_card { background: url('../public/img/profiles/rf.jpg') no-repeat; }

.profiles_player_content {
	float: right;
	width: 400px;
	height: 400px;
	padding-top: 30px;
	color: #fff;
}

.profiles_player_table {
	width: 100%;
}

.profiles_player_table .cname {
	font-family: 'Cinzel', serif;
	text-align: center;
	font-size: 24px;
	color: #fff;
}

.profiles_player_table .cclass {
	text-align: center;
	font-size: 11px;
	color: #eee;
}

.profiles_player_table .isoffline {
	color: #ff0000;
}

.profiles_player_table .isonline {
	color: #00ff00;
}

.profiles_player_table_info {
	table-layout: fixed;
	margin-top: 10px;
}
.profiles_player_table_info tr td {
	padding: 3px 10px;
}
.profiles_player_table_info tr td:first-child {
	text-align: right;
}
/*
OVERRIDE
*/
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
  border-top: none !important;
}

a:focus, a:hover {
  color: inherit !important;
  text-decoration: underline !important;
}

.form-control {
background: #fafafa;
border: 1px solid #e3e3e3;
color: #666;
}
.form-control:focus {
border: 1px solid #ccc;
}

.nopadding {
 padding: 0 !important;
 margin: 0 !important;
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.thumbnail {
  background-color: #f1f1f1;
  border: 1px solid #e3e3e3;
}
a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
border-color: #ff0000;
}

.btn-primary {
  color: #ff0000;
  background-color: transparent;
border-color: #ff0000;
-moz-transition: all .1s ease-in;
  -o-transition: all .1s ease-in;
  -webkit-transition: all .1s ease-in;
  transition: all .1s ease-in;
}
.btn-primary:active, .btn-primary:focus, .btn-primary:hover {
  color: #fff !important;
  background-color: #ff0000 !important;
border-color: #ff0000 !important;
}
/*
APP
*/
#stats-block {
	height:125px;
	width:400px;
	background:url(../public/img/status-bg.jpg) no-repeat top left;
	margin-top:55px;
}
#stats-block-right {
	height:125px;
	width:400px;
	background:url(../public/img/status-bg.jpg) no-repeat top left;
	margin-top:55px;
}
.mblock div {
  float:left;
}
.mblock div img  {
  float:left;
}
.server-time {
  float:left;
}
.server-time ul {
    list-style: none;
    color:#f7ddbf;
    margin-top:20px;
    padding-left:0px;
}
.server-time ul li {
    padding-left:0px;
    text-align:left;
}
#iframa {
  height:390px;
  width:610px;
  overflow: hidden;
}

.loadinggif {
	background-image: url('../public/loading-gif.gif');
	background-repeat:no-repeat;
	background-position: center center;
	background-size: 50px;
	min-height:100%;
	width: 50px;
	height: 50px;
	margin:auto;
  }
  
  /* ----- */
  #root {
	height: 100%!important;
	background-color: black;
  }
  .offcanvas, .offcanvas-lg, .offcanvas-md, .offcanvas-sm, .offcanvas-xl, .offcanvas-xxl {
	--bs-offcanvas-width: 330px!important;
	background-color: darkgrey;
  }
  
  .bd-placeholder-img {
	font-size: 1.125rem;
	text-anchor: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
  }
  
  html, body, #app, #app>div {
	height: 100%
  }
  
  main {
	display: flex;
	flex-wrap: nowrap;
	height: 100vh;
	max-height: 100vh;
	overflow-x: auto;
	overflow-y: hidden;
  }
  
  .b-example-divider {
	flex-shrink: 0;
	width: 1.5rem;
	height: 100vh;
	background-color: rgba(0, 0, 0, .1);
	border: solid rgba(0, 0, 0, .15);
	border-width: 1px 0;
	box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
  }
  
  .bi {
	vertical-align: -.125em;
	pointer-events: none;
	fill: currentColor;
  }
  
  .dropdown-toggle { outline: 0; }
  
  .nav-flush .nav-link {
	border-radius: 0;
  }
  .navbar-nav .nav-link:hover {
	color:white!important;
	font-weight: 500;
	text-decoration:none!important;
  }
  .navbar-nav .nav-link:focus {
	background-color: red;
	color:white!important;
	font-weight: 500;
	text-decoration:none!important;
  }
  
  .btn-toggle {
	display: inline-flex;
	align-items: center;
	padding: .25rem .5rem;
	font-weight: 600;
	color: rgba(0, 0, 0, .65);
	background-color: transparent;
	border: 0;
  }
  .btn-toggle:hover,
  .btn-toggle:focus {
	color: rgba(0, 0, 0, .85);
	background-color: #d2f4ea;
  }
  
  .btn-toggle::before {
	width: 1.25em;
	line-height: 0;
	content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
	transition: transform .35s ease;
	transform-origin: .5em 50%;
  }
  
  .btn-toggle[aria-expanded="true"] {
	color: rgba(0, 0, 0, .85);
  }
  .btn-toggle[aria-expanded="true"]::before {
	transform: rotate(90deg);
  }
  
  .btn-toggle-nav a {
	display: inline-flex;
	padding: .1875rem .5rem;
	margin-top: .125rem;
	margin-left: 1.25rem;
	text-decoration: none;
  }
  .btn-toggle-nav a:hover,
  .btn-toggle-nav a:focus {
	background-color: #d2f4ea;
  }
  
  .scrollarea {
	overflow-y: auto;
  }
  
  .fw-semibold { font-weight: 600; }
  .lh-tight { line-height: 1.25; }
  .loading-gif {
	background-image: url('../public/loading-gif.gif');
	background-repeat:no-repeat;
	background-position: center center;
	background-size: 50px;
	min-height:100%;
	width: 50px;
	height: 50px;
	margin:auto;
  }
  
  .menu-block {
	height: 100vmax;
	height: 100%;
	position: fixed;
  }
  
  #video {
	height:200px;
	overflow:hidden;
  }
  .nav-sub-item {
	padding-bottom:10px
  }
  .nav-sub-item li {
	font-size:13px;
  }
  .nav-sub-item li a:hover {
	text-decoration: none;
  }

  #chat-log p {
	font-size: 12px;
	margin-bottom:2px;
  }
