html {
  overflow: -moz-scrollbars-vertical; 
  overflow-y: scroll;
}

footer {
  bottom:0;
}
body.modal-open {
  padding-right: 0px!important;
}